.card-extend{
    border-radius: 6px !important;
    border: 0px;
    width: 376px;
    margin: auto;
    height: 552px;
}
.card-extend .ant-card-head-wrapper{
    height: 43px;
}
.card-extend .ant-card-head{
    padding: 0 12px !important;
}
.head-card{
    width: 18px;
    height: 18px;
    border-radius:12px;
    margin-top: 5px;
    background: #282829;
    float: right;
    margin-left: 5px;
    cursor: pointer;
}
.head-card img:hover{
    filter: brightness(14);
}
.head-card-title{
    margin: 0px 0px;
    display: flex;
    height: 21px;
    width: 304px;
    border: 1px solid #282829;
    border-radius:15px;
}
.head-card-title-label{
    background: #282829;
    color: #ffe100;
    width: 82px;
    line-height: 21px;
    border-radius: 11px 13px 13px 11px;
    padding: 0px 10px;
    font-size: 13px;
}

.preview-tabs-head{

}
.preview-tabs-head .ant-tabs-nav{
    margin-bottom: 0px !important;
}
.preview-tabs-head .ant-tabs-tab{
    background: #a3a3a3 !important;
    height: 24px;
    width: 74px;
    margin-left: 3px !important;
    text-align: center;
    border-radius: 6px 6px 0px 0px !important;
    border:0px !important;
}
.preview-tabs-head  .ant-tabs-tab-active .ant-tabs-tab-btn{
    width: 95px;
    margin-left: 1px;
}
.preview-tabs-head .ant-tabs-tab-btn{
    margin-left: -4px;
    width: 74px;
}
.preview-tabs-head .ant-tabs-nav-wrap{
    display: flex;
}
.preview-tabs-head .ant-tabs-nav-wrap .ant-tabs-nav-list{
    align-items: flex-end;
}
.preview-tabs-head .ant-tabs-tab-active{
    height: 30px;
    width: 95px;
    background-color: #ffe100 !important;
}
.preview-tabs-head .ant-tabs-nav .ant-tabs-tab-active{
    border-bottom-color: #ffe100 !important;
}
.preview-tabs-head .ant-tabs-tab:first-child{
    margin-left: 7px !important;
}
.preview-tabs-head .ant-tabs-nav::before{
    border-bottom: 0px solid #a3a3a3;
}
.preview-tabs-head-label{
    font-weight: bold;
    font-size: 13px;
    text-align: center;
    color: #717071;
}
.preview-tabs-head .ant-tabs-tab-active .preview-tabs-head-label{
    color: #282829;
}

.preview-tabs-content{
    /*width: 344px;*/
    width: 100%;
}
.preview-tabs-content table tr{
    height: 24px;
}
.preview-tabs-content table tr td{
    border-top: 1px solid #a3a3a3;
    border-bottom: 1px solid #a3a3a3;
    padding-left: 5px;
    text-align: left;
}
.preview-tabs-content table tr td:not(:last-child){
    border-right:1px solid #a3a3a3;
}
.preview-tabs-content table tr:first-child{
    border-top:2px solid #a3a3a3;
}
.preview-tabs-content table tr:last-child{
    border-bottom:2px solid #a3a3a3;
}
.cancel-bold{
    font-weight: normal;
}
.equipment-name-background{
    background: #d2d3d4;
}
.alarm-hint-tooltip{
    color: #282829;
    margin-top: 0px;
    width: 165px;
}
.equipment-name{
    margin-top:2px;
}
.equipment-name-ellipsis{
    margin-top:2px;
    overflow:hidden;
    width: 110px;
    text-overflow:ellipsis;
    white-space:nowrap;
}
.alarm-hint-tooltip{

}
.alarm-hint-tooltip-content{
    margin-top:2px;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
}
.preview-tabs-content-digital{
    display: block;
    width: 14px;
    height: 14px;
    border-radius:50%;
    position: relative;
    margin-top: 3px;
    /*border: 1px solid #282829;*/
}
.preview-tabs-content-digital::before{
    content: ''; /* 伪元素需要内容来触发显示，即使它是空的 */
    width: 4px; /* 白色元素的宽度 */
    height: 4px; /* 白色元素的高度 */
    background-color: #d5e5cf; /* 白色背景 */
    position: absolute; /* 绝对定位，相对于最近的已定位祖先元素（这里是.red-background） */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* 精确居中 */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 添加立体效果的阴影 */
    border-radius: 50%; /* 可选：给白色元素添加圆角 */
}
.table-borderless{
    font-size: 12px;
    width: 100%;
    font-weight: bold;
}