body {
  margin: 0;
  font-family: 'Microsoft YaHei',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body *{
  /*scrollbar-color: auto;  /*使用浏览器默认的滚动条样式 */
  /*scrollbar-color: dark; /* 使用浏览器默认的深色或者黑色滚动效果 */
  /*scrollbar-color: light; /* 使用浏览器默认的浅色滚动效果 */
  scrollbar-color: #808080 #bebebe; /* 第一个颜色为滚动条的颜色， 第二个颜色为滚动条轨道的颜色 */
  scrollbar-width: auto; /* 使用浏览器默认的滚动宽度 */
  scrollbar-width: thin;  /*设置比默认滚动条宽度更窄的宽度 */
  /*scrollbar-width: none;  /*隐藏滚动条，但是元素还是可以滚动 */
  /*scrollbar-width: 66px; /*直接设置滚动条的宽度，比如 60px 3vh 4wh 5rem 6rm 等长度 */
}
.site-layout-content *::-webkit-scrollbar, .scrollbar-extend *::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
  scrollbar-width: thin;
}
.site-layout-content *::-webkit-scrollbar-thumb, .scrollbar-extend *::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
  background: #808080;
}
.site-layout-content *::-webkit-scrollbar-track, .scrollbar-extend *::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(87, 175, 187, 0.1);
  border-radius: 10px;
  background: #bebebe;
}

:root{
  --headerBackgroundColor: #282829;
  --headerMenuBackgroundColor: #ffe100;
  --headerMenuColor: #282829;
  --contentBackgroundColor: #a3a3a3;
  --contentColor: #282829;
  --contentTabsColor: #282829;
  --enterpriseMenuColor: #a3a3a3;
  --enterpriseMenuColorActive: #ffe100;
  --enterpriseMenuBackgroundColor: #1e1e1f;
  --cardPreviewBackgroundColor: #e5e6e6;
  --modalBodyStyleBackgroundColor: #e6e6e6;
  --fontColor:#282829;
  --fontColorHover:#ffe100;
  --iconColor:#282829;
  --iconColorHover:#ffe100;
}